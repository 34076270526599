body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 20px;
}

.expert-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  position: relative;
  z-index: 1;
}

.expert-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.expert-card:hover {
  transform: translateY(-10px);
}

.expert-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.expert-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: contain;
  transition: transform 0.2s;
}

.expert-image:hover {
  transform: scale(1.1);
}

.social-icon {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  transition: transform 0.2s;
}

.social-icon:hover {
  transform: scale(1.1);
}

.expert-card-content {
  padding: 20px;
  text-align: left;
}

.expert-card-content h3 {
  margin-top: 0;
  font-family: cursive;
  font-size: 1.4em;
  color: #333;
}

.expert-card-content p {
  margin: 10px 0;
  font-family: Georgia, serif;
  color: #666;
}

.expert-card-content p strong {
  color: #333;
}

.read-more {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.read-more:hover {
  color: #0056b3;
}

.book-now-btn {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 20px;
  background-color: hsl(308, 100%, 50%);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.2s;
  align-self: center;
}

.book-now-btn:hover {
  background-color: #0056b3;
}

.modal {
  display: flex; 
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
  z-index:11;
  display: flex;                /* Add this line */
  flex-direction: column;       /* Add this line */
  align-items: center;          /* Add this line */
}

.modal-content img {
  display: block;
  margin: 0 auto;
  width: 50%;
}

.modal-content .social-icon {
  width: 40px;
  height: 40px;
  margin: 10px;
  transition: transform 0.2s;
}

.modal-content .social-icon:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .modal-content img {
    width: 70%;
  }

  .modal-content .social-icon {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 480px) {
  .modal-content img {
    width: 90%;
  }

  .modal-content .social-icon {
    width: 30px;
    height: 30px;
  }
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .expert-list {
    grid-template-columns: 1fr 1fr;
  }

  .expert-image {
    width: 80px;
    height: 80px;
  }

  .expert-card-content h3 {
    font-size: 1.2em;
  }

  .expert-card-content p {
    font-size: 0.9em;
  }

  .book-now-btn {
    width: 80%;
  }

  .social-icon {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 480px) {
  .expert-list {
    grid-template-columns: 1fr;
  }

  .expert-image {
    width: 70px;
    height: 70px;
  }

  .expert-card-content h3 {
    font-size: 1em;
  }

  .expert-card-content p {
    font-size: 0.8em;
  }

  .book-now-btn {
    width: 80%;
  }

  .social-icon {
    width: 25px;
    height: 25px;
  }
}

.floating-shape {
  position: absolute;
  opacity: 0.7;
  animation: float 6s ease-in-out infinite;
  z-index: 1;
}

.shape1 {
  width: 100px;
  height: 100px;
  background-color: rgba(255, 99, 132, 0.2);
  border-radius: 50%;
  top: 10%;
  left: 15%;
  animation-duration: 6s;
  z-index: 2;
}

.shape2 {
  width: 80px;
  height: 80px;
  background-color: rgba(54, 162, 235, 0.2);
  border-radius: 50%;
  top: 40%;
  left: 75%;
  animation-duration: 8s;
  z-index: 2;
}

.shape3 {
  width: 120px;
  height: 120px;
  background-color: rgba(75, 192, 192, 0.2);
  border-radius: 50%;
  top: 70%;
  left: 30%;
  animation-duration: 7s;
  z-index: 2;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
