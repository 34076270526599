.how-it-works {
  padding: 40px 20px;
  background-color: #f8f9fa;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.how-it-works h2 {
  font-family: Roboto, sans-serif;
  font-size: 2rem;
  margin-bottom: 20px;
}

.steps {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  position: relative; 
}

.step {
  max-width: 200px;
  text-align: center;
  position: relative; 
}

.step img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.step h3 {
  font-family: Roboto, sans-serif;
  color: #8C8E91;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.step p {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  color: #333448;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow svg {
  width: 40px;
  height: 40px;
}

.shape {
  position: absolute;
  border-radius: 50%;
  opacity: 0.3;
  animation: float 6s ease-in-out infinite;
  z-index: 0;
}

.shape.circle { background-color: #ff6f61; }
.shape.square { background-color: #4072E3; border-radius: 0; }

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .steps {
    flex-direction: column;
    gap: 20px; 
  }

  .arrow {
    transform: rotate(90deg);
    margin: 20px 0; 
  }

  .arrow svg {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 480px) {
  .how-it-works {
    padding: 20px 10px;
  }

  .how-it-works h2 {
    font-size: 1.5rem;
  }

  .step {
    max-width: 100%;
  }

  .step img {
    width: 80%; 
  }

  .step h3 {
    font-size: 1rem;
  }

  .step p {
    font-size: 0.9rem;
  }

  .arrow svg {
    width: 20px;
    height: 20px;
  }
}
