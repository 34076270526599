.header {
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo {
  width: 48px;
  height: 48px;
  padding: 4px 0 0 0;
}

.header-text {
  font-family: 'Roboto', sans-serif;
  font-size: 24px; 
  font-weight: 500;
  line-height: 35.2px;
  text-align: left;
  color: #0F172A; 
}

@media (max-width: 768px) {
  .header {
    padding: 8px 15px;
  }

  .logo {
    width: 40px;
    height: 40px;
  }

  .header-text {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 5px 10px;
    justify-content: center;
  }

  .logo {
    width: 32px;
    height: 32px;
  }

  .header-text {
    font-size: 16px;
    line-height: 24px;
  }
}
