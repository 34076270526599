.connect {
  position: relative;
  width: 100%;
  height: 100vh; 
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.background-mask {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
  background-color: #4072E3;
  opacity: 0.05;
  border-radius: 30%; 
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.overlay {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
  padding: 1rem;
}

.text-content {
  z-index: 2;
  margin-bottom: 2rem;
}

.text-content h1 {
  font-family: Arial, sans-serif;
  font-size: 3rem;
  font-weight: 800;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 1.5rem;
  transition: transform 0.5s ease-in-out;
}

.text-content p {
  font-family: Arial, sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 2rem;
  transition: transform 0.5s ease-in-out;
}

.text-content button {
  font-family: Arial, sans-serif;
  width: 12rem; 
  height: 3rem; 
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 60px 60px 60px 60px;
  cursor: pointer;
  gap: 10px;
  opacity: 1; 
  transition: transform 0.5s ease-in-out;
}

.text-content button:hover {
  background-color: #0056b3;
}

.experts {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  flex-wrap: wrap;
}

.expert {
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  transition: transform 1.5s ease-in-out;
}

.expert1 { top: 10%; left: 15%; width: 4rem; height: 4rem; }
.expert2 { top: 20%; right: 20%; width: 5rem; height: 5rem; }
.expert3 { bottom: 10%; left: 20%; width: 3rem; height: 3rem; }
.expert4 { bottom: 20%; right: 10%; width: 4.5rem; height: 4.5rem; }
.expert5 { top: 5%; right: 30%; width: 3.5rem; height: 3.5rem; }
.expert6 { bottom: 15%; left: 25%; width: 5.5rem; height: 5.5rem; }

.shape {
  position: absolute;
  border-radius: 50%;
  opacity: 0.3;
  animation: float 6s ease-in-out infinite;
  z-index: 0;
}

.shape.circle { background-color: #ff6f61; }
.shape.square { background-color: #4072E3; border-radius: 0; }

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1.25rem);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .text-content h1 {
    font-size: 2.5rem;
    line-height: 1.2;
  }

  .text-content p {
    font-size: 1rem;
    line-height: 1.5;
  }

  .text-content button {
    width: 10rem;
    height: 2.5rem;
    font-size: 0.9rem;
  }

  .expert1, .expert2, .expert3, .expert4, .expert5, .expert6 {
    width: 3rem;
    height: 3rem;
  }
}

@media (max-width: 480px) {
  .connect {
    height: auto;
    padding: 2rem 1rem;
  }

  .background-mask {
    width: 80vw;
    height: 80vw;
  }

  .text-content h1 {
    font-size: 2rem;
    line-height: 1.2;
  }

  .text-content p {
    font-size: 0.9rem;
    line-height: 1.4;
  }

  .text-content button {
    width: 8rem;
    height: 2.25rem;
    font-size: 0.8rem;
  }

  .expert1, .expert2, .expert3, .expert4, .expert5, .expert6 {
    width: 2.5rem;
    height: 2.5rem;
  }
}
