.footer {
  padding: 40px 20px;
  background-color: #FFFFFF;
  color: white;
  text-align: center;
}

.footer p {
  font-family: Roboto, sans-serif;
  color: #000000;
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: bold;
}

.social-links a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

@media (max-width: 768px) {
  .footer {
    padding: 30px 15px;
  }

  .footer p {
    font-size: 1.5rem;
  }

  .social-links a {
    margin: 0 8px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 20px 10px;
  }

  .footer p {
    font-size: 1.2rem;
  }

  .social-links a {
    margin: 0 5px;
  }
}
